"use strict";

// import '../lib/smooth-scroll/smooth-scroll.polyfills.min.js';
// import '../lib/jquery-3.4.1.mins.js';
// import $ from "jquery";
/*global  jQuery*/
// jQuery(function ($) {
// $(function () {
//  //読み込みが完了
//  $(window).on('load',function () { 
//    console.log('jQuery Page Loaded.');  
//  });
 
//  var state = false;
//  var scrollpos;

//  $('#menu-icon,#menu-background').on('click', function(){
//    if(state == false) {
//      scrollpos = $(window).scrollTop();
//      $('body').addClass('js-fixed').css({'top': -scrollpos});
//      state = true;
//    } else {
//      $('body').removeClass('js-fixed').css({'top': 0});
//      window.scrollTo( 0 , scrollpos );
//      state = false;
//    }
//  });

// });
// });


   //ページトップ戻るボタン 表示ギミック
   $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $(".el_btnPagetop").addClass('visible');
    } else {
      $(".el_btnPagetop").removeClass('visible');
    }
  });
  
  //ページトップ戻るボタン 動作
  $(document).on("click", ".el_btnPagetop", function () {
   console.log('DOM Content Loaded.');
    $('body,html').velocity("scroll", 500);
    $('.el_btnPagetop').blur();
  });
  
  // スムーズスクロール 
  $(document).on('click', 'a[href*=\\#]:not([href=\\#]).js_linkScroll', function (event) {
    var href = $(this).attr("href"),
    target = $(href === "#" || href === "" ? 'html' : href);
    target.velocity("scroll", 500);
    return false;
  });

// DOM読み込み完了
document.addEventListener('DOMContentLoaded', function(e) {
  console.log('DOM Content Loaded.');

  var menubtn = document.querySelectorAll('.btn-menu')[0];
  var menu = document.querySelectorAll('.site-menu .menu')[0];
  
  var menuParent = document.getElementsByClassName('menu-item-has-children');
  for( var i = 0; i < menuParent.length; i++ ) {
    menuParent[i].insertAdjacentHTML('afterbegin','<div class="toggle-submenu close"></div>');
  }

  // var spmenubtn = document.querySelectorAll('.toggle-submenu');
  var spmenubtn = document.getElementsByClassName('toggle-submenu');
  for (let i = 0; i < spmenubtn.length; i++) {
    // fuga[i]で現在の要素を指定
    spmenubtn[i].addEventListener('click', function() {
      spmenubtn[i].classList.toggle('close');
      spmenubtn[i].classList.toggle('open');
    }, false);
  }

  // var scroll = new SmoothScroll('a[href*="#"]',{
  //   // header: '[data-scroll-header]'
  // });

  var state = false;
  var scrollpos;
  var menuIcon = document.getElementById('menu-icon');
  var menuBackground = document.getElementById('menu-background');
  var body = document.querySelectorAll('body')[0];

  function scrollStop() {
    if(state == false) {
      scrollpos = window.scrollY;
      body.classList.add('js-fixed');
      body.style.top = -scrollpos + 'px';
      // $('body').addClass('js-fixed').css({'top': -scrollpos});
      state = true;
    } else {
      body.classList.remove('js-fixed');
      body.style.top = 0;
      // $('body').removeClass('js-fixed').css({'top': 0});
      window.scrollTo( 0 , scrollpos );
      state = false;
    }
  }

  menuIcon.addEventListener('click',scrollStop);
  menuBackground.addEventListener('click',scrollStop);

}, false);

// 完全に読み込み完了
window.addEventListener('load', function() {
  console.log('Page Loaded.');
});
